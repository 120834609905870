html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Sporting Grotesque_Regular';
  src: url('./assets/font/Regular/Sporting_Grotesque-Regular_web.eot');
  src: url('./assets/font/Regular/Sporting_Grotesque-Regular_web.eot?#iefix') format('embedded-opentype'),
  url('./assets/font/Regular/Sporting_Grotesque-Regular_web.woff') format('woff'),
  url('./assets/font/Regular/Sporting_Grotesque-Regular_web.woff2') format('woff2'),
  url('./assets/font/Regular/Sporting_Grotesque-Regular_web.ttf') format('truetype'),
  url('./assets/font/Regular/Sporting_Grotesque-Regular_web.svg#svgFontName') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sporting Grotesque_Bold';
  src: url('./assets/font/Bold/Sporting_Grotesque-Bold_web.eot');
  src: url('./assets/font/Bold/Sporting_Grotesque-Bold_web.eot?#iefix') format('embedded-opentype'),
  url('./assets/font/Bold/Sporting_Grotesque-Bold_web.woff') format('woff'),
  url('./assets/font/Bold/Sporting_Grotesque-Bold_web.woff2') format('woff2'),
  url('./assets/font/Bold/Sporting_Grotesque-Bold_web.ttf') format('truetype'),
  url('./assets/font/Bold/Sporting_Grotesque-Bold_web.svg#svgFontName') format('svg');
  font-weight: normal;
  font-style: normal;
}


:root {
  --bg: #3b60ff;
  --text: #f7f7f7;
  --link: #21ba45;
}

body {
  //background-color: var(--bg);
  font-family: 'Sporting Grotesque_Regular', serif;
}

.main {
  width: 100vw;
}

a {
  color: var(--text);
  text-decoration: underline;
}

a:hover {
  color: var(--link);
  text-decoration: underline;
}

p, h1, h2, h3, h4 {
  color: var(--text);
  font-family: 'Sporting Grotesque_Regular', serif;
}

p {
  font-size: 18px;
  line-height: 1.4285em;
  max-width: 800px;
}

h3 {
  font-size: 1.4em;
  margin-bottom: 2em;
}

.rsvp h4 {
  font-size: 18px;
  font-weight: 600;
  color: #3c3c3c;
}

#root {
  width: 100%;
}

#header {
  display: flex;
  justify-content: space-between;
  padding: 25px 45px;
  width: 100%;
}

#header p, #bottom p {
  font-size: 12px;
}

#bottom p {
  width: 100%;
  margin: 0 auto;
}

#bottom {
  text-align: center;
}

.top_part {
  height: 100vh;
  min-height: 100vh;
}

.text-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

#dove {
  max-width: 210px;
  margin: 5em auto;
}

#doveS {
  max-width: 65px;
  margin: 2em auto;
}

#kochani {
  max-width: 500px;
  padding: 0.5em 2em;
  margin-bottom: 2em;
}

#kochani p {
  font-size: 18px;
  margin-bottom: 3em;
}

.text-overlay h1 {
  font-size: 4.5em;
}

#maps_link {
  white-space: nowrap;
}

.photo-overlayA {
  height: 90vh;
  margin: 35px;

  .img-backdropA {
    height: 100%;
    background: url("./assets/photos/3s.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    padding: 20px;

    img {
      display: none;
    }
  }
}

.photo-overlayB {
  margin: 35px;

  .img-backdropB {
    height: 100%;
    background: url("./assets/photos/horizontal2.jpg") no-repeat center center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    width: 100%;
    padding: 20px;

    img {
      display: none;
    }
  }
}

#info {
  width: 100%;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
}

#address {
  margin: 40px;
}

#spacer {
  width: 100%;
}

.ui.vertical.segment {
  margin: 50px 10px;
  border: none;
}

.stripe {
  width: 100%;
}

.ui.green.button, .ui.green.buttons .button {
  background-color: var(--text);
  color: var(--bg);
}

.ui.container.rsvp {
  padding-top: 50px;
}

.map-container {
  height: 50vh;
  background: blue;
}

.leaflet-container {
  width: 100%;
  height: 50vh;
  z-index: 1;
}

.pull-right {
  text-align: right;
}

.justified {
  text-align: justify;
}

p.center {
  text-align: center;
}

.stripe img {
  width: 100%;
}

.ui.container.text.splash {
  padding: 50px 40px;
  max-width: 590px !important;
}

.ui.form {
  input[type="text"], textarea {
    background: #EEEEEE;
  }
}

@media (max-width: 600px) {
  .photo-overlayA {
    .img-backdropA {
      background-position-x: 62%;

      .text-overlay {
        top: 0;
        padding-top: 50%;
        width: 100%;
        height: 100%;

        h1 {
          word-spacing: 600px;
        }
      }
    }
  }
  .photo-overlayB {
    .img-backdropB {
      background-position-x: 62%;

      .text-overlay {
        top: 0;
        padding-top: 50%;
        width: 100%;
        height: 100%;

        h1 {
          word-spacing: 600px;
        }
      }
    }
  }

  .top_part {
    padding-bottom: 2rem;
  }
}

@media (max-width: 500px) {
  .photo-overlayA {
    .img-backdropA {
      background-position-x: 55%;
    }
  }
}

@media (max-width: 1000px) {
  #top_name {
    display: none;
  }
  #header {
    justify-content: center;
  }
  .photo-overlayA, .photo-overlayB {
    margin: 20px;
  }
}

@media (max-width: 500px) {
  .text-overlay h1 {
    font-size: 3em;
  }
  .photo-overlayA, .photo-overlayB {
    margin: 0px;
  }
}

@media only screen and (max-height: 738px) {
  #dove {
    max-height: 140px;
    margin: 3em auto;
  }
}

@media only screen and (max-height: 738px) {
  #dove {
    max-height: 140px;
    margin: 3em auto;
  }
}

@media only screen and (max-height: 500px) {
  .top_part {
    height: auto;
    min-height: 100vh;
  }
}

.gallery {
  text-align: center;
  img {
    margin-left: 5px;
    cursor: zoom-in;
  }

  p {
    text-align: center;
    max-width: 100%;
    color: black;
    padding-bottom: 1rem;

    a {
      color: var(--bg);
      text-decoration: none;

      &:hover {
        color: darken(#3b60ff, 30);
      }
    }
  }
}